<template>
  <main air class="prod">
    <sub-menu />
    <p-section class="hero">
      <div class="holder" v-prx:sticky>
        <div class="bg" v-prx:progress="{ r:[3,6], o:[0,1] }"></div>
        <h2 v-prx:progress="{ r:[3,6], o:[1,0] }">공조 카트리지</h2>
        <p v-prx:progress="{ r:[3,6], o:[1,0] }">넓고 쾌적한 공간</p>
        <div class="img" v-prx:progress="{
            MS: { r:[4,10], ty:[0,-180], o:[1,0] },
            TL: { r:[4,10], ty:[0,-500], o:[1,0] }
        }">
          <img src="/img/cartridge/air-hero.png" alt="" >
          <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
        </div>
        <div class="overview" v-prx:progress="{ r:[6,10], o:[0,1], ty:[80,0] }">
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">공조 카트리지</p>
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">
            공조 카트리지는 내부 온도와 공기 순환을 최적화합니다. 주요설비를 창밖에 배치하여 공조기 및 배관이 점유하던 실내 면적을 온전히 활용할 수 있습니다. 맑은 공기와 쾌적한 온도를 실시간으로 조절하고 에너지 손실을 최소화합니다.</p>
        </div>
      </div>
    </p-section>
    <p-section class="feature">
      <div class="holder" v-prx:sticky>
        <ul v-prx:progress="{ r:[1,11], pan:[0,100] }">
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/air-feature-ico-1.svg" alt="" class="ico">
              <p>효과적인 <br>공간 활용</p>
              <p>건물 내부에 위치했던 공조기와 냉∙난방 시스템을 카트리지화 하여 창밖에 배치하였습니다. 이를 통해 실내 면적을 온전히 활용할 수 있습니다.</p>
              <div class="video">
                <video src="/img/cartridge/air-feature-video-1.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/air-feature-video-1.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/air-feature-ico-2.svg" alt="" class="ico">
              <p>효율적인<br>공기 순환</p>
              <p>카트리지는 공간의 온도와 습도를 적절히
                조절하고, 맑은 공기를 실시간으로 공급하며 에너지 손실을 최소화합니다.</p>
              <div class="video">
                <video src="/img/cartridge/air-feature-video-2.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/air-feature-video-2.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/air-feature-ico-3.svg" alt="" class="ico">
              <p>영역별<br>별도 제어</p>
              <p>모듈 단위로 제공되는 시스템을 통해 영역별 별도 제어가 가능합니다. 공간을 필요에 맞게 구분하고 온・습도를 영역별로 조절할 수 있습니다.</p>
              <div class="video">
                <video src="/img/cartridge/air-feature-video-3.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/air-feature-video-3.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
                <p class="astar">* 이해를 돕기 위해 연출된 이미지로 실제 제품과 다를 수 있습니다.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </p-section>
    <p-section class="structure">
      <div class="holder" v-prx:sticky>
        <h3>간편한 <br class="hidden-tp-up">설치와 교체</h3>
        <img src="/img/cartridge/air-structure.s.png" alt="" class="hidden-tl-up">
        <img src="/img/cartridge/air-structure.l.png" alt="" class="hidden-tp-down">
        <dl>
          <dt>① 외부 프레임_옵션1</dt>
          <dd>깃털 타입 적용</dd>
          <dt>② 외부 프레임_옵션2</dt>
          <dd>글래스 타입 적용</dd>
          <dt>③ 공조 카트리지</dt>
          <dd>냉난방 환기 시스템</dd>
          <dt>④ FIT 프레임</dt>
          <dd>내부 스킨을 포함한 외장 함체</dd>
          <dt>⑤ 가이드 프레임</dt>
          <dd>FIT 프레임 지지/기반</dd>
        </dl>
        <p>직관적인 구조를 통해 카트리지를 간편하게 설치하고, 손쉽게 교체할 수 있습니다. 예기치 않은 변화와 다양한 요구에 유연하게 대응합니다.</p>
      </div>
    </p-section>
    <section class="spec">
      <div class="inner-wrap">
        <h3>제품 사양</h3>
        <ul>
          <li>
            <img src="/img/cartridge/air-spec.png">
            <p>공조 카트리지</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 3,950mm</dd>
              <dd><b>너비</b> 850mm</dd>
              <dd><b>깊이</b> 1,000mm</dd>
              <dd><b>무게</b> 400kg</dd>
            </dl>
            <dl>
              <dt>공조 사양</dt>
              <dd><b>기본</b> 냉방 / 난방 / 환기 / 공기정화</dd>
              <dd><b>열원</b> 수냉식 / 공냉식</dd>
              <dd><b>풍량 (SA/RA)</b> 86~100CCM / 86~100CCM</dd>
              <dd><b>정압 (SA/RA)</b> 63mmAq / 17mmAq</dd>
              <dd><b>냉방 / 난방</b> 27.1kW / 18.8kW</dd>
              <dd><b>옵션</b> 마감 및 색상 커스텀 가능</dd>
              <dd><b>Coil</b> Φ3/8*4R*56S*460EL</dd>
              <dd><b>팬 (SA/RA)</b> EC Fan (DL450)</dd>
              <dd><b>팬 모터</b> 1.8kW / 1.3kW</dd>
              <dd><b>필터</b> PRE 20t / MIDIUM 50t</dd>
            </dl>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>
<script>

import SubMenu from '@/views/common/SubMenu.vue';

export default {
  name: 'CartridgeSkin',
  components: { SubMenu },
  metaInfo() {
    return {
      title: 'FIT Cartridge',
      meta: [
        { vmid: 'title', content: 'FIT Cartridge', },
        { vmid: 'description', content: 'FIT Cartridge는 예기치 않은 변화와 다양한 요구에 유연하게 대응하며 다양한 카트리지 디자인을 통해 건물의 아이덴티티 제공합니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
@import "~@/less/prod.less";

[air].prod {
  .hero {
    img { .w(132); }
  }
}

@media (min-width: @screen-tp-min) {
  [air].prod {
    .hero {
      img { .w(144); }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [air].prod {
    .hero {
      img { .w(198); }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [air].prod {
    .hero {
      img { .w(255); }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [air].prod {
    .hero {
      img { .w(230); }
    }
  }
}
</style>
